<template>
  <div class="particu">
    <!-- 详情页面公用组件 -->
    <div class="particu_name">
      <div>
        <div class="title">
          {{ tradeGoodsListing ? goodsNames : tradeGoods.goodsName }}
        </div>
        <div class="number">
          商品编号：<span>{{ tradeGoods.goodsNum }}</span>
        </div>
      </div>

      <div>
        <div style="text-align: left">
          <div style="font-size: 20px; font-weight: bold">
            {{ tradeGoods.enterpriseName }}
          </div>
          <div>
            <i class="hy-icon-dianhua"></i>
            {{ tradeGoods.contactInfo }}
          </div>
        </div>
      </div>
    </div>
    <!-- 竞价 -->
    <div class="particu_cont" v-if="tradeBidding">
      <div class="particu_items">
        <div class="item_images" v-if="tradeBidding">
          <span v-if="tradeBidding.taxIncluded == 0">含税</span
          ><span v-if="tradeBidding.taxIncluded == 1">不含税</span>
          <span v-if="tradeBidding.priceType == 0">未知</span>
          <span v-if="tradeBidding.priceType == 1">坑口价</span>
          <span v-if="tradeBidding.priceType == 2">出厂价</span>
          <span v-if="tradeBidding.priceType == 3">船板价</span>
          <span v-if="tradeBidding.priceType == 4">平仓价</span>
          <span v-if="tradeBidding.priceType == 5">车板价</span>
          <span v-if="tradeBidding.priceType == 6">到厂价</span>
        </div>
        <div
          v-if="goodsCategoryList[0].goodsPrice"
          style="margin-left: 20px; margin-top: 5px"
        >
          <span style="color: rgb(208, 17, 26)">￥</span
          ><span style="color: rgb(208, 17, 26); font-size: 30px">{{
            goodsCategoryList[0].goodsPrice
          }}</span
          >元/吨
        </div>
        <!-- <div
          style="margin-left: 100px; margin-top: 5px"
          v-if="tradeGoods?.deliveryMode"
        >
          配送方式:
          <span>{{
            tradeGoods?.deliveryMode == 1
              ? "送货"
              : tradeGoods?.deliveryMode == 2
              ? "自提"
              : ""
          }}</span>
        </div> -->
        <div v-if="tradeBidding.bond" style="margin-left: 100px">
          保证金:<span
            style="color: rgb(208, 17, 26); marin-left: 100px; font-size: 30px"
            >{{ tradeBidding.bond }}万元</span
          >
        </div>

        <div v-if="tradeGoods.stockCount" style="margin-left: 100px">
          <span v-if="tradeGoods.orderType == 2 && tradeGoods.tradeType == 2"
            >需求数量:</span
          >
          <span v-else> 库存数量:</span>
          <span
            style="color: rgb(208, 17, 26); font-size: 30px"
            v-if="tradeGoods.stockCount > 0"
            >{{ tradeGoods.stockCount }}吨
          </span>
          <span v-else> 以实际结算数量为准 </span>
        </div>
        <div v-if="pageData.orderCount" style="margin-left: 100px">
          下单数量:
          <span style="color: rgb(208, 17, 26); font-size: 30px">{{
            pageData.orderCount
          }}</span
          >吨
        </div>
      </div>

      <!-- <div v-if="pageData.residueTime">
        <div>
          <div style="display: flex; align-items: center; margin-bottom: 9px;font-size: 16px;color:#333">
            <div
              style="
                width: 53px;
                height: 1px;
                background: #2a8de9;
                display: inline-block;
              "
            ></div>
            <div style="margin:0 5px">距离结束还有</div>
            
            <div
              style="
                width: 53px;
                height: 1px;
                background: #2a8de9;
                display: inline-block;
              "
            ></div>
          </div>
          <CountDown
            v-if="pageData.residueTime"
            :endDate="`${pageData.residueTime}`"
          ></CountDown>
        </div>
      </div> -->
    </div>

    <!-- 挂牌 -->
    <div
      class="particu_item"
      style="align-items: baseline"
      v-if="tradeGoodsListing"
    >
      <div class="item_images">
        <span v-if="tradeGoodsListing.taxIncluded == 0">含税</span>
        <span v-if="tradeGoodsListing.taxIncluded == 1">不含税</span>
        <span v-if="tradeGoodsListing.priceType == 0">未知</span>
        <span v-if="tradeGoodsListing.priceType == 1">坑口价</span>
        <span v-if="tradeGoodsListing.priceType == 2">出厂价</span>
        <span v-if="tradeGoodsListing.priceType == 3">船板价</span>
        <span v-if="tradeGoodsListing.priceType == 4">平仓价</span>
        <span v-if="tradeGoodsListing.priceType == 5">车板价</span>
        <span v-if="tradeGoodsListing.priceType == 6">到厂价</span>
      </div>
      <div style="margin-left: 20px" v-if="tradeGoods.orderType == 2">
        价格：<span style="color: #d0111a">￥</span>
        <span style="color: #d0111a; font-size: 30px">{{
          goodsCategoryList[0].goodsPrice == 0
            ? "面议"
            : goodsCategoryList[0].goodsPrice
        }}</span>
        <span v-if="goodsCategoryList[0].goodsPrice != 0">/吨</span>
      </div>
      <!-- <div
        style="margin-left: 20px"
        v-if="tradeGoods.ifAdjustPrice == 1 && $route.query.orderId"
      >
        <el-button type="text" @click="handleHistoryPrice"
          >查看历史价格</el-button
        >
      </div> -->
      <div
        v-if="pageData.orderCount && pageData.orderTypeBack != 4"
        style="margin-left: 100px"
      >
        订单数量:
        <span style="color: #d0111a; font-size: 30px">{{
          pageData.orderCount
        }}</span
        ><span style="color: #d0111a">吨</span>
      </div>

      <div
        v-if="pageData?.tradeGoods?.ifInvite == 1"
        style="margin-left: 100px"
      >
        配送方式:
        <span
          >{{
            tradeGoods?.deliveryMode == 1
              ? "送货"
              : tradeGoods?.deliveryMode == 2
              ? "自提"
              : ""
          }}{{ isListingSales ? "(以实际拉运方式为准)" : "" }}</span
        >
      </div>
      <div
        v-if="tradeGoods && pageData.orderTypeBack != 4 && $route.query.goodsId"
        style="margin-left: 100px"
      >
        <span v-if="tradeGoods.orderType == 2 && tradeGoods.tradeType == 1"
          >需求数量:</span
        >
        <span v-else>
          <span v-if="tradeGoods.orderType == 1 && tradeGoods.tradeType == 1">
            库存数量:</span
          >
        </span>
        <span>
          <span
            style="color: #d0111a; font-size: 30px"
            v-if="tradeGoods.ifConsultRealityCount == 0"
            >{{ tradeGoods.stockCount }}</span
          >
          <span
            style="color: #d0111a"
            v-if="tradeGoods.ifConsultRealityCount == 0"
            >吨</span
          >
        </span>
        <span
          style="font-size: 20px"
          v-if="tradeGoods.ifConsultRealityCount != 0"
          >以实际结算数量为准</span
        >
      </div>

      <div v-if="pageData.orderTypeBack == 4" style="margin-left: 100px">
        <span>下单数量:</span>
        <span v-if="pageData.orderCount != 0">
          <span style="color: #d0111a; font-size: 30px">{{
            pageData.orderCount
          }}</span
          ><span style="color: #d0111a">吨</span>
        </span>
        <span v-else>以实际结算数量为准</span>
      </div>
    </div>
    <div
      class="goods-list"
      style="margin-top: 10px"
      v-if="isListingSales || tradeBidding"
    >
      <table border="1">
        <tr>
          <th>商品编号</th>
          <th>商品分类</th>
          <th>商品名称</th>
          <th>
            {{
              !tradeBidding
                ? "价格(元/吨)"
                : $route.query.goodsId
                ? "竞拍底价(元/吨)"
                : "报价金额"
            }}
          </th>
          <th v-if="tradeBidding">
            {{ tradeGoods.orderType == 2 ? "需求" : "库存" }}数量(吨)
          </th>
        </tr>
        <tr v-for="(item, index) in goodsCategoryList" :key="index">
          <td>{{ item.goodsNum }}</td>
          <td>{{ item.goodsTypeName }}</td>
          <td>{{ item.goodsName }}</td>
          <td>
            <span
              :style="{ color: tradeBidding ? 'rgb(208, 17, 26)' : '#212121' }"
              >{{ item.goodsPrice == 0 ? "面议" : item.goodsPrice }}</span
            >
          </td>
          <td v-if="tradeBidding">{{ item.number }}</td>
        </tr>
      </table>
    </div>
    <div class="auction" v-if="ifInvite != 1">
      <h3>发布方</h3>
      <div class="auction_item" v-if="tradeGoods.payType">
        <div v-if="tradeGoods.payType">
          <span style="display: inline-block; width: 100px">支付方式：</span>
          <span>{{ tradeGoods.payType | initPayType }}</span>
        </div>
      </div>
      <div class="auction_item" v-if="tradeGoods.deliveryMode">
        <div v-if="tradeGoods.deliveryMode">
          <span style="display: inline-block; width: 100px">配送方式：</span>
          <span
            >{{
              tradeGoods.deliveryMode == 1
                ? "送货"
                : tradeGoods.deliveryMode == 2
                ? "自提"
                : ""
            }}{{ isListingSales ? "(以实际拉运方式为准)" : "" }}</span
          >
        </div>
      </div>

      <div class="auction_item" v-if="tradeGoodsListing">
        <div v-if="tradeGoodsListing.ifSplit != null">
          <span style="display: inline-block; width: 100px">是否可拆分：</span>
          <span>{{
            tradeGoodsListing.ifSplit == 0 ? "标的物可拆分" : "标的物不可拆分"
          }}</span>
        </div>
      </div>
      <div class="auction_item" v-if="tradeBidding">
        <div>
          <span style="display: inline-block; width: 100px">竞价日期：</span>
          <span>{{ tradeBidding.startTime }} ~ {{ tradeBidding.endTime }}</span>
        </div>
      </div>
      <!-- <div class="auction_item" v-if="tradeGoods.deliveryStartTime&&tradeGoods.deliveryEndTime">
        <div class="relation">
          <span style="display: inline-block; width: 100px">交割日期：</span>
          <span
            >{{ tradeGoods.deliveryStartTime }} ~
            {{ tradeGoods.deliveryEndTime }}</span
          >
        </div>
      </div> -->
    </div>
    <div
      class="remark"
      v-if="tradeGoodsListing && tradeGoodsListing.offShelfTime"
    >
      <div style="width: 100px">下架时间：</div>
      <div>{{ tradeGoodsListing.offShelfTime }}</div>
    </div>

    <div class="relation" v-if="ifInvite != 1">
      <span style="display: inline-block; width: 100px">联系方式：</span>
      <span>{{ tradeGoods.contacts }}</span>
      <span style="margin-left: 20px; width: 100px">{{
        tradeGoods.contactInfo
      }}</span>
    </div>
    <div class="remark" v-if="tradeBidding && tradeBidding.ifMinNumber == 1">
      <div style="width: 100px">最低参与人数：</div>
      <div>{{ tradeBidding.minNumber }}</div>
      <el-popover
        placement="top-start"
        title=""
        trigger="hover"
        content="当前商品的竞价人数未达到最低参与人数则竞价时间结束后无人中标"
      >
        <div slot="reference">
          <i class="el-icon-question"></i>
        </div>
      </el-popover>
    </div>
    <div class="remark" v-if="tradeBidding">
      <div style="width: 100px">交货期限：</div>
      <div>{{ tradeBidding.deliveryPeriod }}天</div>
    </div>
    <div class="remark" v-if="ifInvite != 1">
      <div style="width: 100px">交割地：</div>
      <div>{{ tradeGoods.deliveryPlace }}</div>
    </div>
    <div class="remark" v-if="ifInvite != 1">
      <div style="width: 100px">产地：</div>
      <div>{{ tradeGoods.originPlace }}</div>
    </div>

    <div
      style="
        margin-top: 20px;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
      "
      v-if="pageData.feedCompanyName && ifInvite != 1"
    >
      <h3>参与方</h3>
      <div class="relation">
        <span style="display: inline-block; width: 100px">单位名称：</span>
        <span>{{ pageData.feedCompanyName || "" }}</span>
      </div>
      <div class="relation">
        <span style="display: inline-block; width: 100px">联系方式：</span>
        <span>{{ pageData.feedPhone || "" }}</span>
      </div>
    </div>

    <div
      class="indicator"
      v-for="(goods, index) in goodsCategoryList"
      :key="index"
    >
      <div style="width: 100px">
        {{ goods.goodsTypeName }}{{ goods.goodsName }}指标：
      </div>
      <div class="tables">
        <table border="1">
          <tr class="tables_tr">
            <td v-for="(item, index) in goods.quotas" :key="index">
              {{ item.name }}（{{ item.unit.split(";")[0] }}）{{
                item.unit.split(";")[2]
              }}
            </td>
          </tr>
          <tr class="tables_tr">
            <td v-for="(item, index) in goods.quotas" :key="index">
              {{ item.value }}{{ item.unit.split(";")[1] }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div v-if="tradeGoodsListing">
      <div
        class="indicator"
        v-for="(goods, index) in goodsCategoryList"
        :key="index"
      >
        <div style="width: 100px">
          {{ goods.goodsTypeName }}{{ goods.goodsName }}指标：
        </div>
        <div class="tables">
          <table border="1">
            <tr class="tables_tr">
              <td v-for="(item, index) in goods.quotas" :key="index">
                {{ item.name }}（{{ item.unit.split(";")[0] }}）{{
                  item.unit.split(";")[2]
                }}
              </td>
            </tr>
            <tr class="tables_tr">
              <td v-for="(item, index) in goods.quotas" :key="index">
                {{ item.value }}{{ item.unit.split(";")[1] }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div> -->

    <el-dialog
      title="查看历史价格"
      :visible.sync="priceHistoryVisible"
      :modal-append-to-body="false"
      width="30%"
    >
      <el-table
        :data="priceHistoryList"
        border
        style="width: 100%; margin-top: 10px"
      >
        <el-table-column prop="createTime" label="时间" width="180">
        </el-table-column>
        <el-table-column prop="price" label="价格" width="180">
        </el-table-column>
        <el-table-column prop="fileUrl" label="文件">
          <template slot-scope="scope">
            <el-button type="text" @click="downloadFile(scope.row.fileUrl)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <div class="remark" v-if="ifInvite != 1">
      <div style="width: 100px">备注说明：</div>
      <div>{{ tradeGoods.remark }}</div>
    </div>
  </div>
</template>

<script>
import { tradeDtetails, orderDetail, listAdjustPrice } from "@/api/public.js";
const { baseUrl } = require("@/config");
export default {
  data() {
    return {
      tradeGoods: {},
      tradeGoodsListing: {},
      quota: [],
      tradeBidding: null,
      pageData: {},
      selectOrderType: null,
      ifInvite: null,
      priceHistoryVisible: false,
      priceHistoryList: [],
      baseUrl: baseUrl,
      goodsCategoryList: [],
    };
  },
  components: {
    // CountDown: () => import("@/components/countDowns.vue"),
  },
  filters: {
    listingPrice(data) {
      if (data || data == 0) {
        return data == 0 ? "面议" : "￥" + data + "/吨";
      } else {
        return "";
      }
    },
    initPayType(val) {
      let arr = ["未知", "现金", "承兑", "现金+承兑"];
      return arr[val];
    },
  },
  computed: {
    isListingSales() {
      return (
        this.pageData?.tradeGoodsListing &&
        this.pageData?.tradeGoods?.orderType == 1
      );
    },
    goodsNames() {
      return this.goodsCategoryList.reduce((pre, cur, index) => {
        return (
          pre +
          cur.goodsName +
          (index == this.goodsCategoryList.length - 1 ? "" : ",")
        );
      }, "");
    },
  },
  mounted() {
    this.selectOrderType = this.$route.query.selectOrderType;
    this.ifInvite = this.$route.query.ifInvite;
    if (this.$route.query.goodsId) {
      this.getlisst(this.$route.query.goodsId);
    } else if (this.$route.query.orderId) {
      this.orderList(this.$route.query.orderId);
    }
  },
  props: {},
  methods: {
    downloadFile(fileUrl) {
      if (!fileUrl) {
        this.$message.console.error("暂无文件信息");
        return;
      }
      const link = document.createElement("a"); // 创建a标签
      link.href = this.baseUrl + "third/third/ossGetFile/" + fileUrl;
      let fileName = "file";
      link.target = "_blank";
      // link.download = fileName; // 文件重命名，若无需重命名，将该行删去
      link.click();
      URL.revokeObjectURL(url); // 释放内存
      // window.open(this.baseUrl + "third/third/ossGetFile/" + fileUrl);
    },
    handleHistoryPrice() {
      listAdjustPrice({
        dealStatus: 1,
        orderId: this.$route.query.orderId,
      }).then((res) => {
        if (res.code == 0) {
          this.priceHistoryList = res.data || [];
          this.$nextTick(() => {
            this.priceHistoryVisible = true;
          });
        }
      });
    },
    getlisst(id) {
      // let goodsId = this.$route.query.goodsId;
      tradeDtetails(id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.pageData = res.data;
          if (res.data.quotas) {
            let quotas = res.data.quotas;
            quotas.forEach((item) => {
              item.name =
                item.name +
                `(${item.unit.split(";")[0]})` +
                `${item.unit.split(";")[2]}`;
              item.value = item.value + `${item.unit.split(";")[1]}`;
            });
            this.quota = quotas;
          }
          if (res.data.tradeGoods) {
            let newlist = res.data.tradeGoods;
            newlist.ifConsultRealityCount = res.data.ifConsultRealityCount;
            if (newlist.createTime) {
              newlist.createTime = newlist.createTime.replace("T", " ");
            }
            newlist.createTime = newlist.createTime.replace("T", " ");
            if (newlist.deliveryStartTime) {
              newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
                "T",
                " "
              );
            }
            if (newlist.deliveryEndTime) {
              newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
                "T",
                " "
              );
            }
            this.tradeGoods = newlist;
          }
          if (res.data.tradeBidding) {
            let list = res.data.tradeBidding;
            for (const key in list) {
              if (key == "startTime") {
                list[key] = list[key].replace("T", " ");
              }
              if (key == "endTime") {
                list[key] = list[key].replace("T", " ");
              }
            }
            this.tradeBidding = list;
          }
          if (res.data.goodsCategoryList) {
            this.goodsCategoryList = res.data.goodsCategoryList.map((item) => {
              return { ...item };
            });
          } else if (res.data.orderCategoryList) {
            this.goodsCategoryList = res.data.orderCategoryList.map((item) => {
              return { ...item };
            });
          }
          this.$emit("child-event", res.data);
          if (res.data && res.data.tradeGoodsListing) {
            this.tradeGoodsListing = res.data.tradeGoodsListing;
          } else {
            this.tradeGoodsListing = null;
          }
        }
        console.log(this.goodsCategoryList, 323232);
      });
    },
    orderList(id) {
      let config = {
        orderId: id,
      };
      orderDetail(config)
        .then((res) => {
          if (res.code == 0) {
            this.pageData = res.data;
            if (res.data.quotas) {
              let quotas = res.data.quotas;
              quotas.forEach((item) => {
                item.name =
                  item.name +
                  `(${item.unit.split(";")[0]})` +
                  `${item.unit.split(";")[2]}`;
                item.value = item.value + `${item.unit.split(";")[1]}`;
              });
              this.quota = quotas;
            }
            if (res.data.tradeGoods) {
              let newlist = res.data.tradeGoods;
              if (newlist.createTime) {
                newlist.createTime = newlist.createTime.replace("T", " ");
              }
              newlist.createTime = newlist.createTime.replace("T", " ");
              if (newlist.deliveryStartTime) {
                newlist.deliveryStartTime = newlist.deliveryStartTime.replace(
                  "T",
                  " "
                );
              }
              if (newlist.deliveryEndTime) {
                newlist.deliveryEndTime = newlist.deliveryEndTime.replace(
                  "T",
                  " "
                );
              }
              newlist.ifConsultRealityCount = res.data.ifConsultRealityCount;
              this.tradeGoods = newlist;
            }
            console.log(res.data, "orderdetail");
            if (res.data.tradeBidding) {
              let list = res.data.tradeBidding;
              for (const key in list) {
                if (key == "startTime") {
                  list[key] = list[key].replace("T", " ");
                }
                if (key == "endTime") {
                  list[key] = list[key].replace("T", " ");
                }
              }
              this.tradeBidding = list;
            }
            if (res.data.tradeBidding) {
              this.goodsCategoryList = res.data.goodsCategoryList.map(
                (item) => {
                  if (this.$route.query.goodsId) {
                    return { ...item };
                  }
                  if (this.$route.query.orderId) {
                    return {
                      ...item,
                      ...{ floorPrice: item.goodsPrice },
                      ...{ goodsPrice: item.dealPrice },
                    };
                  }
                }
              );
            }
            if (res.data.tradeGoodsListing && res.data.orderCategoryList) {
              this.goodsCategoryList = res.data.orderCategoryList.map(
                (item) => {
                  return {
                    ...item,
                    ...{
                      quotas:
                        res.data.goodsCategoryList?.find(
                          (goods) => goods.categoryId == item.categoryId
                        )?.quotas || [],
                    },
                  };
                }
              );
            } else if (
              res.data.tradeGoodsListing &&
              res.data.goodsCategoryList
            ) {
              this.goodsCategoryList = res.data.goodsCategoryList.map(
                (item) => {
                  return {
                    ...item,
                  };
                }
              );
            }
            this.$emit("child-event", res.data);
            if (res.data && res.data.tradeGoodsListing) {
              this.tradeGoodsListing = res.data.tradeGoodsListing;
              console.log(this.tradeGoodsListing);
            } else {
              this.tradeGoodsListing = null;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item_images {
  width: 100px;
  height: 24px;
  background: url("../assets/images/tag.png") no-repeat;
  background-size: 100px 100%;
  color: white;
  padding-left: 3px;
  padding-top: 2px;
  margin-top: 10px;
  display: flex;
}
.particu_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  margin-top: 20px;
}
.particu {
  padding: 20px;
  border: 1px solid #ccc;
  .particu_name {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      margin-top: 5px;
      // margin-left: 20px;
    }
  }
  .particu_items {
    display: flex;
    align-items: flex-end;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    // margin-top: 20px;
  }
  .particu_item {
    display: flex;
    // justify-content: space-between;
    // padding: 20px;
    padding: 20px 0;
    // border: 1px solid #ccc;
    border-top: 1px dashed #ccc;
    border-bottom: 1px dashed #ccc;
    margin-top: 20px;
  }
  .auction {
    margin-top: 20px;
    .auction_item {
      // display: flex;
      margin-top: 20px;
      .finish {
        // margin-left: 20px;
        margin-top: 20px;
      }
    }
  }
  .relation {
    margin-top: 20px;
  }
  .delivery {
    margin-top: 20px;
  }
  .indicator {
    margin-top: 20px;
    display: flex;
    .tables {
      // margin-top: 10px;
      text-align: center;
      table {
        border: none;
      }
      .tables_tr {
        // display: flex;
        flex-wrap: wrap;
        font-weight: bold;
      }
      td {
        border: none;
        width: 220px;
        padding: 5px 10px;
        border: 1px dashed black;
      }
    }
  }
  .remark {
    margin-top: 20px;
    display: flex;
  }
  .goods-list {
    table {
      tr {
        th,
        td {
          padding: 5px 10px;
          text-align: center;
          border: 1px dashed black;
        }
      }
    }
  }
}
</style>
